import React from "react";

import { ShowroomMap } from "../../../../../UI/ShowroomMap/ShowroomMap";
import { ShowroomProps } from "../../../ShowroomCard/ShowroomCard";

import useLocalization from "../../../../../../hooks/useLocalization";

import * as styles from "./InfoSection.module.scss";

interface InfoSectionProps {
  showroom: ShowroomProps;
  isRetail: boolean;
}

const InfoSection = ({ showroom, isRetail }: InfoSectionProps) => {
  const { translations } = useLocalization();

  const { longitude, latitude } = showroom.location;
  return (
    <div className={styles.InfoSection}>
      <div>
        <h2 className={styles.title}>About this Showroom</h2>
        <p className="p-styles whitespace-pre-wrap text-secondary-color">
          {showroom.description}
        </p>
      </div>

      {longitude && latitude && (
        <div>
          <h2 className={styles.title}>Location</h2>
          <ShowroomMap
            className=""
            location={showroom.location}
            width="952"
            height="355"
            marker={isRetail}
          />
          <div className={styles.locationInfo}>
            {translations["InfoSection--info-text"]}
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoSection;
