import React from "react";

import ShowroomDetails from "../../components/Pages/Showrooms/ShowroomDetails/ShowroomDetails";
import withLayout from "../../hoc/withLayout/withLayout";

function ShowroomPage({ pageContext }) {
  const { showroom } = pageContext;
  return <ShowroomDetails showroom={showroom} />;
}

export default withLayout(ShowroomPage);
