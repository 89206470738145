import React from "react";

const GOOGLE_STATIC_MAP_URL = "https://maps.googleapis.com/maps/api/staticmap";
const GOOGLE_API_KEY = process.env.GATSBY_GOOGLE_MAPS_KEY;

export function ShowroomMap({
  location,
  width = "496",
  height = "285",
  className,
  style = null,
  marker = false,
}) {
  const { latitude, longitude } = location;
  const radiusInMeters = 600;

  // Implementation inspired from stack overflow answer
  // https://stackoverflow.com/a/35660617

  function createPath(lat, lng, rad, detail = 8) {
    let pathString = "&path=fillcolor:0x0a4683|weight:6|color:0x0a4683";

    const r = 6371;

    const pi = Math.PI;

    const _lat = (lat * pi) / 180;
    const _lng = (lng * pi) / 180;
    const d = rad / 1000 / r;

    let i = 0;

    for (i = 0; i <= 360; i += detail) {
      const brng = (i * pi) / 180;

      let pLat = Math.asin(
        Math.sin(_lat) * Math.cos(d) +
          Math.cos(_lat) * Math.sin(d) * Math.cos(brng)
      );
      const pLng =
        ((_lng +
          Math.atan2(
            Math.sin(brng) * Math.sin(d) * Math.cos(_lat),
            Math.cos(d) - Math.sin(_lat) * Math.sin(pLat)
          )) *
          180) /
        pi;
      pLat = (pLat * 180) / pi;

      pathString += "|" + pLat + "," + pLng;
    }
    return pathString;
  }

  const marketPath = marker
    ? `&markers=icon:https://res.cloudinary.com/outer/image/upload/v1623250175/production/outer/static_assets/map-marker.png%7C${latitude},${longitude}`
    : createPath(latitude, longitude, radiusInMeters);

  const path = `${GOOGLE_STATIC_MAP_URL}?sensor=false&size=${width}x${height}${marketPath}&zoom=12&scale=2&center=${latitude},${longitude}&key=${GOOGLE_API_KEY}&style=element:geometry|color:0xf5f5f5&style=element:labels.icon|visibility:off&style=element:labels.text.fill|color:0x616161&style=element:labels.text.stroke|color:0xf5f5f5&style=feature:administrative.land_parcel|element:labels.text.fill|color:0xbdbdbd&style=feature:poi|element:geometry|color:0xeeeeee&style=feature:poi|element:labels.text.fill|color:0x757575&style=feature:poi.park|element:geometry|color:0xe5e5e5&style=feature:poi.park|element:labels.text.fill|color:0x9e9e9e&style=feature:road|element:geometry|color:0xffffff&style=feature:road.arterial|element:labels.text.fill|color:0x757575&style=feature:road.highway|element:geometry|color:0xdadada&style=feature:road.highway|element:labels.text.fill|color:0x616161&style=feature:road.local|element:labels.text.fill|color:0x9e9e9e&style=feature:transit.line|element:geometry|color:0xe5e5e5&style=feature:transit.station|element:geometry|color:0xeeeeee&style=feature:water|element:geometry|color:0xc9c9c9&style=feature:water|element:labels.text.fill|color:0x9e9e9e&`;

  return <img className={className} style={style} src={path} alt="Outer" />;
}
