import React, { useContext } from "react";
import haversine from "haversine";
import cx from "classnames";

import { TooltipInfo } from "../../../../../../components";
import { ShowroomProps } from "../../../ShowroomCard/ShowroomCard";
import ShowroomsContext from "../../../../../../contexts/Showrooms/Showrooms.context";
import { Button } from "../../../../../../components";

import useLocalization from "../../../../../../hooks/useLocalization";

import * as styles from "./ActionSection.module.scss";

interface ActionSectionProps {
  showroom: ShowroomProps;
  isRetail: boolean;
  tooltipText: string;
}

const ActionSection = ({
  showroom,
  isRetail,
  tooltipText,
}: ActionSectionProps) => {
  const {
    state: { userLocation },
  } = useContext(ShowroomsContext);

  const { translations, regionalCopy } = useLocalization();

  const name = `${
    showroom.host.data.first_name
  } ${showroom.host.data.last_name.charAt(0)}.`;

  const distance = Math.round(
    haversine(
      {
        latitude: userLocation.latitude,
        longitude: userLocation.longitude,
      },
      {
        latitude: showroom.location.latitude,
        longitude: showroom.location.longitude,
      },
      { unit: regionalCopy.distanceUnit }
    )
  );

  return (
    <div className={styles.ActionSection}>
      <div className={styles.labelContainer}>
        <h3 className={styles.label}>Location</h3>
        <div className={styles.labelData}>
          {distance && `${distance} ${regionalCopy.displayDistance} | `}
          {showroom.city}, {showroom.state}
        </div>
      </div>
      <div className={styles.labelContainer}>
        <h3 className={styles.label}>Showroom Type</h3>
        <div className={styles.labelData}>
          {isRetail
            ? "Retail Showroom"
            : translations["ActionSection--tooltip-condition"]}
          <span className="showDesktop">
            <TooltipInfo tooltipText={tooltipText} placement="topLeft" />
          </span>
        </div>
      </div>
      <div className={styles.labelContainer}>
        <h3 className={styles.label}>Hosted By</h3>
        <div className={cx(styles.labelData, styles.hostDetails)}>
          <div className={styles.hostAvatar}>
            <img src={showroom.host.data.avatar} alt={name} />
          </div>
          <span>{name}</span>
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        {isRetail ? (
          <Button to={showroom.attributed_link} target="_blank">
            {showroom.attributed_cta}
          </Button>
        ) : (
          <>
            {Boolean(showroom.visitable) && (
              <Button to={`/showrooms/connect-visit?showroomId=${showroom.id}`}>
                Request a Visit
              </Button>
            )}
            {Boolean(showroom.virtual_connectable) && (
              <Button
                to={`/showrooms/connect-virtually?showroomId=${showroom.id}`}
              >
                Connect Virtually
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ActionSection;
